<template>
  <div class="input-wrapper">
    <input
      :disabled="disabled"
      :class="[inputClasses, customClass]"
      ref="input"
      v-bind="$attrs"
      @input="onInput"
      @blur="$emit('blur')"
      :value="computedValue"/>
    <div v-if="hasSuffixIcon" class="icon-wrapper">
      <slot class="icon" name="suffix" />
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: [Number, String],
    customClass: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      newValue: this.value
    }
  },
  computed: {
    computedValue: {
      get () {
        return this.newValue
      },
      set (value) {
        this.newValue = value
        this.$emit('input', value)
      }
    },
    hasSuffixIcon () {
      return !!this.$slots.suffix
    },
    inputClasses () {
      return [
        {
          'has-suffix': this.hasSuffixIcon,
          grey: this.type === 'grey'
        }
      ]
    }
  },
  watch: {
    value (value) {
      this.newValue = value
    }
  },
  methods: {
    onInput (event) {
      this.$nextTick(() => {
        if (event.target) {
          this.computedValue = event.target.value
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/__variables.scss';
.input-wrapper {
  position: relative;
}
input {
  font-weight: 500;
  width: 100%;
  min-height: 100%;
  height: 56px;
  border-radius: 8px;
  padding: 16px;
  color: black;
  font-size: 1.6rem;
  background: white;
  border: none;
  &.has-suffix {
    padding-right: calc(46px + 12px);
  }
  &.error {
    border: 1.5px solid $error;
  }
  &:focus {
    outline: none;
    border: 1.0px solid $secondary;
  }
  &::placeholder {
    color: $placeholder;
  }
  &:disabled {
    background: $bg-disable-placeholder;
    color: $disable-placeholder;
  }
  &.grey {
    background: $light-gray;
    color: $black;
    border: none;
    font-weight: 400;
    font-size: 14px;
    padding: 9px 18px 10px 18px;
    &:focus {
      outline: none;
      border: none;
    }
    &::placeholder {
      color: $black;
    }
  }
}
.icon-wrapper {
  width: 36px;
  height: 36px;
  background: $primary;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 0;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 24px;
  height: 24px;
}
</style>
