<template>
  <div class="login-page">
    <div class="flex-1">
      <div class="is-flex justify-center align-items-center">
        <img src="@/assets/img/logo-white/logo-ptn.png" class="logo"/>
      </div>
      <div class="has-margin-top-100">
        <div class="is-flex justify-flex-end">
          <ChangeLanguage />
        </div>
        <div class="_fs-28 _fw-500 has-text-white has-margin-top-5">{{$t('loginPage.title')}}</div>
        <div class="has-margin-top-20">
          <ValidationObserver ref="loginForm">
            <ValidationProvider
              rules="required"
              name="code"
              v-slot="{ errors }"
            >
            <RInput :placeholder="$t('loginPage.registrationCodes')" v-model="code"/>
            <span class="error">{{ errors[0] }}</span>
          </ValidationProvider>
          </ValidationObserver>
        </div>
        <div class="_fs-14 _fw-500 has-text-white has-margin-top-10 is-flex justify-flex-end" @click="$router.push({ name: 'HowtoGetRegisterCode' })">
          {{$t('loginPage.howto')}}
        </div>
      </div>
    </div>
    <div class="is-flex justify-center">
      <RoundButton type="white" @click="submitCheckEventMember()">{{$t('loginPage.enterCode')}}</RoundButton>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import RInput from '@/components/base/RInput.vue'
import RoundButton from '@/components/base/RoundButton.vue'
import ChangeLanguage from '@/components/ChangeLanguage.vue'
export default {
  components: {
    RInput,
    ChangeLanguage,
    RoundButton
  },
  data () {
    return {
      code: '',
      error: ''
    }
  },
  methods: {
    ...mapActions({
      checkEventMember: 'Auth/checkEventMember'
    }),
    async submitCheckEventMember () {
      this.$wait.start('app loading')
      try {
        await this.checkEventMember(this.code)
        this.$router.push({ name: 'Register' })
        // const events = resp
        // if (events && events.length > 0) {
        //   this.$router.push({ name: 'Register' })
        // } else {
        //   this.error = '*รหัสสำหรับลงทะเบียนไม่ถูกต้อง'
        //   this.showErrorToast()
        // }
      } catch (e) {
        console.log(e)
        this.error = this.$t('loginPage.error')
        this.showErrorToast()
      } finally {
        this.$wait.end('app loading')
      }
    },
    showErrorToast () {
      this.$buefy.toast.open({
        message: this.error,
        position: 'is-bottom',
        type: 'is-danger'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login-page {
  padding: 16px 36px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .logo {
    width: 160px;
  }
}
</style>
