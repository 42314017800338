<template>
  <div class="change-language" :class="{
    'large': isLarge
  }">
    <div class="dropdown-toggle is-flex justify-space-between align-items-center" @click="toggleMenu()">
      <div class="is-flex align-items-center justify-flex-start">
        <img :src="$i18n.locale === 'th' ? require('@/assets/icon/TH_flag.svg') : require('@/assets/icon/EN_flag.svg')" class="has-margin-right-5"/>
        <span v-if="isLarge">{{ $i18n.locale === 'th' ? 'ภาษาไทย (TH)' : 'English (EN)' }}</span>
        <span v-else>{{ $i18n.locale.toUpperCase() }}</span>
      </div>
      <img :src="showMenu ? require('@/assets/icon/chevron-down.svg') : require('@/assets/icon/chevron-up.svg')"/>
    </div>
    <div class="dropdown-menus" v-if="showMenu">
      <div v-for="(lang, index) in $i18n.availableLocales" :key="lang">
        <div class="lang-item" :class="{
          'divider': (index % 2 === 0 && index < $i18n.availableLocales.length - 1 )
        }" @click="switchLocale(lang)">
          <div class="selected-btn" :class="{
            'selected': lang === $i18n.locale
          }"></div>
          <div class="is-flex align-items-center justify-flex-start">
            <img :src="lang === 'th' ? require('@/assets/icon/TH_flag.svg') : require('@/assets/icon/EN_flag.svg')" class="has-margin-right-5"/>
            <span v-if="isLarge">{{ lang === 'th' ? 'ภาษาไทย (TH)' : 'English (EN)' }}</span>
            <span v-else>{{ lang.toUpperCase() }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showMenu: false
    }
  },
  props: {
    closeOnOutsideClick: {
      type: [Boolean],
      default: true
    },
    isLarge: {
      type: [Boolean],
      default: false
    }
  },
  mounted () {
    if (this.closeOnOutsideClick) {
      document.addEventListener('click', this.clickHandler)
    }
  },
  beforeDestroy () {
    document.removeEventListener('click', this.clickHandler)
  },
  methods: {
    toggleMenu () {
      this.showMenu = !this.showMenu
    },
    clickHandler (event) {
      const { target } = event
      const { $el } = this
      if (!$el.contains(target)) {
        this.showMenu = false
      }
    },
    switchLocale (locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/__variables.scss';
.change-language {
  width: 120px;
  position: relative;
  vertical-align: middle;
  font-size: 1.6rem;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  &.large {
    width: 220px;
    .dropdown-toggle {
      padding: 10px 30px;
    }
    .dropdown-menus {
      bottom: 100% !important;
      top: unset !important;
      margin-top: 0px;
      margin-bottom: 8px;
    }
  }
  .dropdown-toggle {
    padding: 10px 16px;
    border-radius: 28px;
    background-color: white;
    width: 100%;
  }
  .dropdown-menus {
    margin-top: 8px;
    width: 100%;
    /* min-height: 136px; */
    padding: 0px 18px;
    border-radius: 28px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    background-color: white;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
  }
  .lang-item {
    padding: 20px 0px;
    display: flex;
    align-items: center;
    cursor: pointer;
    &.divider {
      border-bottom: 1px solid $greyd5;
    }
    .selected-btn {
      margin-right: 12px;
      width: 24px;
      height: 24px;
      border-radius: 100%;
      border: solid 1px $grey97;
      background-color: $greyd8;
      &.selected {
        background: white;
        border: solid 6px $secondary;
      }
    }
  }
}
</style>
